@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto !important;
}
#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  border: 1px solid var(--black-01, #262020);
  background: var(--gray-01, #f5f5f5);
}
.noDecor {
  text-decoration: none;
}
.leftSideHeader {
  width: 20%;
  height: 60px;
  gap: 6px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--black-01, #262020);
}
.rightSideHeader {
  height: 60px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.rightSideHeader a {
  text-decoration: none;
}

.rightSideHeader a button {
  text-transform: none;
}

.blackButton,
#singleADDTodo button {
  border-radius: 4px;
  background: var(--black-01, #262020);
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  height: fit-content;
}
.nouppercase {
  text-transform: none !important;
}
#singleADDTodo input {
  width: 80%;
  border-radius: 4px;
  border: 1px solid var(--gray-03, #c0c0c0);
  background: #fff;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  padding: 10px;
}
body .rdw-option-wrapper {
  align-items: center;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: center;
  margin: 0 4px;
  min-width: 35px;
  padding: 11px;
  text-transform: capitalize;
}

input:focus {
  outline: none !important;
}
.wrapperTOPTOP {
  position: relative;
}
.whiteButton {
  color: var(--black-01, #262020);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid var(--black-01, #262020);
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.backgroundLogin {
  background: url("../public/login.jpeg") center center no-repeat;
  background-size: cover;
  height: calc(100vh - 61px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
}

.backgroundLogin .form {
  background: #f4f4f4e5;
  padding: 20px;
  max-width: 90vw;
  width: 600px;
}
.backgroundLogin .form form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.backgroundLogin .form form .title {
  color: var(--black-01, #262020);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.backgroundLogin .form form input {
  border-radius: 4px;
  border: 1px solid var(--gray-03, #c0c0c0);
  background: #fff;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  padding: 10px;
}
.backgroundLogin .form form input::placeholder {
  color: var(--gray-03, #c0c0c0);
}
.backgroundLogin .form form input:focus {
  outline: none !important;
}
.backgroundLogin .form form button {
  width: fit-content;
  padding: 8px 24px;
  color: var(--black-01, #262020);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.84px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid var(--black-01, #262020);
}

.backgroundLogin .form form a {
  text-decoration: none;
  color: var(--black-01, #262020);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}
.layoutWrapper {
  padding: 10px 40px;
  min-height: calc(100vh - 61px);
  background: #f5f5f5;
}
#underheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 40px;
  position: relative;
}

#underheader .rightSide a {
  text-decoration: none;
}

.closeTodo button {
  background: transparent;
}
#underheader button.chatGPT {
  display: flex;
  width: 220px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--black-01, #262020);
  background: #f5f5f5;
  color: var(--black-01, #262020);
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 100% */
  letter-spacing: -0.76px;
}
#underheader .leftSide {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 6px;
}
#underheader .leftSide::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
}

/* Track */
#underheader .leftSide::-webkit-scrollbar-track {
  background: #bfbfbf;
}

/* Handle */
#underheader .leftSide::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#underheader .leftSide::-webkit-scrollbar-thumb:hover {
  background: #555;
}
h1.name {
  color: #000;
  text-align: left;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36.538% */
  letter-spacing: -2.08px;
  text-transform: uppercase;
}
p.last_seen {
  margin-bottom: 0;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
}

.wrapperTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.wrapperTop button {
  border-radius: 4px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 24px;
}

.wrapperTop .inputWrapper {
  position: relative;
}
.wrapperTop .inputWrapper svg {
  position: absolute;
  top: 10px;
  left: 10px;
}

.wrapperTop .inputWrapper input {
  border-radius: 6px;
  border: 1px solid #000;
  height: 37px;
  padding-left: 30px;
}
.wrapperTop .inputWrapper input:focus {
  outline: none;
}

.todolistItem .left {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.todolistItem .left svg {
  font-size: 26px;
}

.todolistItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  justify-content: space-between;
}

.todolistItem:hover {
  background: #ececec;
}

.todoForm .inputTypical {
  width: 100%;
}

.todolistWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.todoWidth {
  min-width: 300px;
  width: 28%;
}
.projectsTODO {
  display: flex;
  flex-direction: row;
  border: 1px solid #000;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
}
.black {
  color: black;
}
#companyTOp #icons div svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
div.leftTodoList {
  min-width: 300px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #abdb23;
  background: #fff;
  box-shadow: 0px 440px 123px 0px rgba(0, 0, 0, 0),
    0px 282px 113px 0px rgba(0, 0, 0, 0.01),
    0px 158px 95px 0px rgba(0, 0, 0, 0.05),
    0px 70px 70px 0px rgba(0, 0, 0, 0.09), 0px 18px 39px 0px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  min-height: 70vh;
  flex-shrink: 0;
  width: 22%;
}
.leftTodoList .yellowBG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  margin-bottom: 20px;
}

.leftTodoList .yellowBG strong {
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.38px;
}
.leftTodoList .yellowBG span {
  color: var(--tekst-body-gray03, #f7f7f7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.tasksWrapper {
  display: flex;
  flex-direction: column;
}
.tasksWrapper .task {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
}

.tasksWrapper .task p {
  margin-bottom: 0;
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  width: 80%;
}
.thrashIcon {
  cursor: pointer;
  width: 24px;
}

.icon .circleYellowFull,
.outlineYellowCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f0a139;
}
.icon .outlineYellowCircle {
  width: 22px;
  height: 22px;
  background: #a8a8a866;
}
.task.rozpoczete {
  align-items: start;
}
.rozpoczeteTasks p.projectName {
  color: var(--tekst-display-gray-01, #2f2a2a);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-top: 5px;
}

.rozpoczeteTasks {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.projectsTODO {
  width: 100%;
  overflow: auto;
  max-height: 70vh;
}

.projectsTODO::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
}

/* Track */
.projectsTODO::-webkit-scrollbar-track {
  background: #bfbfbf;
}

/* Handle */
.projectsTODO::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.projectsTODO::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.projectsTODO .todoProject {
  padding: 15px;
  border-right: 1px solid #000;
  flex-shrink: 0;
}
.todoProject .title > span {
  position: absolute;
  right: 0px;
  top: -25px;
}

.todoProject .title {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  border-radius: 4px;
  background: #eee;
  padding: 10px;
  align-items: center;
  height: 43px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todoProject .title div {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.todoProject .title div strong {
  max-width: calc(100% - 35px);
  text-overflow: ellipsis;
  white-space: nowrap;
}
.todoProject .title form {
  width: 30px;
}

.todoProject .title form input {
  width: 100%;
  background: transparent;
  border: none;
}

.todoProject .title form input::-webkit-outer-spin-button,
.todoProject .title form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.todoProject .title form input[type="number"] {
  -moz-appearance: textfield;
}

.todoProject .title > strong {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
}

.todoProject .title > span {
  border-radius: 4px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 0px 5px;
}
.todoProject .title > span strong {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.todoProject .title > span span {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.28px;
}
.todoProject .icon svg {
  font-size: 24px;
}

.outlineYellowCircle.yellowBG {
  background: #a8a8a866;
}
.todoProject .tasksWrapper .task .icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.todoProject .tasksWrapper .task .icon#active {
  background: #000000;
  color: white;
}
.todoProject .tasksWrapper .task .icon#active svg {
  color: white;
}

.todoProject .tasksWrapper .task.subTODO .icon {
  width: 17px;
  height: 17px;
  margin-left: 3px;
}
.todoProject .icon {
  cursor: pointer;
}
.todoProject .tasksWrapper .task.subTODO .icon div {
  width: 15px;
  height: 15px;
}
.todoProject .tasksWrapper .task.subTODO .icon svg {
  width: 8px;
  height: 8px;
}
.tasksWrapper .task.subTODO p {
  font-size: 14px;
}
.icon .outlineYellowCircle#yellowBG {
  background: #a8a8a866;
}

.singleTodo {
  width: 78%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.singleTodo .leftSideSingleTodo {
  width: calc(100% - 310px);
}

.singleTodo .rightSideSingleTodo {
  width: 300px;
  max-width: 100%;
}

.singleTodo .comment {
  border-radius: 4px;
  border: 1px solid #abdb23;
  height: fit-content;
  padding: 10px;
}

.singleTodo .comment .topComment {
  border-radius: 4px;
  background: var(--green01, #abdb23);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  color: white;
}

.singleTodo .comment .topComment strong {
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}

.singleTodo .comment textarea {
  border: none;
  width: 100%;
  height: 100%; /* Make the textarea fill the div initially */
  box-sizing: border-box; /* Include padding and border in the textarea's dimensions */
  resize: none; /* Prevent manual resizing by the user */
  overflow: hidden; /* Hide overflow to allow smooth expansion */
}

.singleTodo .comment textarea:focus {
  outline: none;
}
.commentContent {
  height: 100%;
  position: relative;
  max-height: 50vh;
  overflow: auto;
}
.singleTodo .comment textarea::-webkit-scrollbar {
  display: none;
}
.singleTodo .Header .name {
  color: #000;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
}
.tasksWrapper p.todoSchedules#active,
.tasksWrapper .task p#active,
.tasksWrapper .todoSchedules#active small {
  font-weight: 700;
}

.smallTextDate {
  color: var(--tekst-body-gray02, #d1d1d1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.rdw-editor-toolbar {
  width: fit-content;
  position: absolute;
  top: 10px;
  background: transparent !important;
  border: none !important;
}
.DraftEditor-editorContainer {
  height: 47vh !important;
}
.rdw-link-modal {
  height: fit-content !important;
  width: fit-content !important;
}
.rdw-image-modal-upload-option-label {
  overflow: hidden;
}
.rdw-option-wrapper {
  background: transparent !important;
}

.rdw-image-imagewrapper {
  max-width: 60%;
}
.rdw-image-imagewrapper img {
  max-width: 100%;
  height: auto;
}

.topBG {
  border-radius: 4px;
  background: var(--green01, #abdb23);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topBG strong {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}
.topBG span {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}
.tasksWrapper .task {
  position: relative;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
}
.tasksWrapper .task:hover {
  box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 11%);
  background: #aadb2315;
}
.projectsTODO .task.subTODO {
  height: 20px;
}

.projectsTODO .task .removeIcon,
.editIcon {
  display: none;
}
.projectsTODO .task:hover .removeIcon,
.task:hover .editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  cursor: pointer;
}
.projectsTODO .task .removeIcon,
.projectsTODO .task.subTODO .removeIcon {
  color: red;
}

.newTodo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.newTodo svg {
  margin-top: -40%;
  cursor: pointer;
}

.removeModalBG {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000065;
  cursor: pointer;
}

.removeModalContent {
  z-index: 9999999;
  background: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  padding: 20px;
  border-radius: 10px;
  right: 0;
  text-align: center;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  min-height: fit-content;
  max-width: 90vw;
  position: absolute;
  top: calc(
    50% - 175px
  ); /* position the top  edge of the element at the middle of the parent */
}
.removeModalContent .buttons {
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}

.chatGPTBLOCK {
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  width: 100%;
  height: 500px;
  padding: 20px;
}

.chatGPTBLOCK p.notes {
  color: var(--black-01, #262020);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
}

.chatGPTBLOCK .overfloAutoText {
  overflow: auto;
  height: 90%;
  width: 100%;
}

.chatGPTBLOCK textarea {
  border: none;
  width: 100%;
  height: 380px;
}
.chatGPTBLOCK textarea:focus {
  outline: none;
}

.chatGPTBLOCK {
  position: relative;
}

.chatGPTBLOCK .MessagesChatGPT {
  height: 100%;
  max-height: 380px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.messChat {
  width: fit-content;
  padding: 2px 6px;
  background: #aaaaaa1f;
  border-radius: 5px;
}

.messChat p {
  margin-bottom: 0;
}

.messChat.chatGPT .top {
  background: #aaaaaa1f;
  border-radius: 5px;
  padding: 2px 6px;
}
.messChat.chatGPT svg {
  width: 30px;
  flex-shrink: 0;
}

.messChat.chatGPT {
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}

.messChat.chatGPT .top {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 10px;
}
.messChat {
  margin-bottom: 10px;
}
.messChat.chatGPT .bottom {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.messChat.chatGPT .bottom svg {
  cursor: pointer;
}

.messChat.chatGPT .top p {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 0;
}

.addMess {
  width: 97%;
  left: 1.5%;
  display: flex;
  border-radius: 100px;
  background: #f5f5f5;
  flex-direction: row;
  padding: 6px 10px;
  align-items: center;
  position: absolute;
  bottom: 20px;
  justify-content: center;
}
.addMess input {
  width: calc(100% - 24px);
  background: transparent;
  border: none;
}

.addMess form {
  width: 100%;
  display: flex;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.233);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  flex-direction: column;
  gap: 30px;
}
#ReactSimpleImageViewer {
  z-index: 1000;
}

.loader p {
  font-size: 15px;
  color: black;
}

.loader.bgWhite {
  background: transparent;
}

.messChat.user {
  text-align: right;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}

.singleTodo .Header {
  border-bottom: 1px solid var(--black-01, #262020);
  padding: 0 0 10px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actions .leftActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions .leftActions .icon {
  cursor: pointer;
}

.actions .leftActions .action {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 26px;
}

.actions .leftActions .action p {
  margin-bottom: 0;
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}

.actions .rightActions .counter {
  height: 26px;
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}

.actions .rightActions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions .center {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.actions .center p {
  color: #000;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.38px;
  text-transform: uppercase;
}

.actions .center input {
  width: 300px;
  border-radius: 4px;
  border: 1px solid var(--black-01, #262020);
  padding: 10px;
}

.toggleHide {
  cursor: pointer;
}

.toggleHide#active {
  transform: rotate(180deg);
  transition: all 0.5s ease-in-out;
}

.toggleHide#notActive {
  transition: all 0.5s ease-in-out;
}

.task input {
  width: 100%;
}
.tasksWrapper p.todoSchedules {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.tasksWrapper p.todoSchedules span {
  width: 75%;
}
.todoSchedules small {
  color: var(--black-01, #262020);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}
.attachements {
  padding: 10px 0;
}
.attachement {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--black-01, #262020);
  background: #fff;
  cursor: pointer;
  position: relative;
}
.icon {
  cursor: pointer;
}

.attachement img {
  width: 100%;
  height: auto;
}
.attachement div.iconsAttachemnt {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 5px;
  right: 5px;
  z-index: 999;
  gap: 5px;
  border-radius: 5px;
  background: #ffffff6c;
}
.pdfAttachemnt p {
  max-width: 100%;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div .public-DraftStyleDefault-block {
  margin: 0.5rem 0;
}
.pdfAttachemnt a {
  max-width: 70%;
}
.pdfAttachemnt .FILENAME {
  line-height: 120px;
  text-transform: uppercase;
}
.attachements {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.attachements .attachement {
  width: calc(25% - 10px);
  padding-top: calc(25% - 10px); /* Match the height to the width */
  position: relative; /* Ensure content inside stays properly positioned */
  overflow: hidden; /* Optional: Prevent content overflow */
}
body .rdw-link-decorator-icon {
  left: 0;
  width: 100%;
  height: 20px;
  opacity: 0;
}

.tutorial {
  width: 100%;
  height: calc(100vh - 62px); /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Optional: Background color for contrast */
}

.attachements .attachement img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
.commentNote {
  margin: 5px 0;
  border-top: 1px solid #abdb23;
  padding: 4px 0;
}
.commentNote p {
  margin-bottom: 0;
}
.commentNote p.author {
  font-size: 11px;
  color: #747474;
}
.commentContent .newComment {
  padding: 5px 0;
}
.commentContent .newComment button,
.editNote {
  border-radius: 4px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: auto;
  padding: 2px 24px;
}

.commentNote #onHover {
  display: none;
}
.commentNote:hover #onHover {
  display: block;
}
.commentNote p.textNote {
  font-size: 13px;
  color: #555;
  text-align: justify;
  margin: 4px 0;
}

.comment p.date {
  font-size: 10px;
  color: #747474;
}

.comment .notifyUser {
  font-size: 24px;
  cursor: pointer;
}
.notifyUserP {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}

.editorWrapper .saving {
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #747474;
}
.pRelative {
  position: relative;
}

.editorWrapper .saving#isSaving {
  color: #f93c00;
}

@media only screen and (max-width: 600px) {
  .attachements .attachement {
    width: calc(100%);
  }
}
.pdfAttachemnt {
  position: absolute;
  top: 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--green01, #abdb23) !important;
  opacity: 1 !important;
}

div.priority {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #f93c00;
  padding: 6px 10px;
  color: #f93c00;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  cursor: pointer;
  height: 26px;
  margin-left: 10px;
}
div.priority#active {
  color: white;
  background: #f93c00;
}
.circleOrange {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #f93c00;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f93c00;
}

.wholeScreenWhite {
  position: fixed;
  left: 0;
  top: 0;
  background: var(--background-gray-00, #f8f8f8);
  z-index: 99;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerForm {
  position: relative;
  border-radius: 6px;
  padding: 20px !important;
  border: 1px solid var(--background-gray-01, #ececec);
  background: #fff;
  max-width: 90vw;
  width: 460px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 5px 5px 0px rgba(0, 0, 0, 0.09),
    0px 12px 7px 0px rgba(0, 0, 0, 0.05), 0px 21px 8px 0px rgba(0, 0, 0, 0.01),
    0px 33px 9px 0px rgba(0, 0, 0, 0);
}

.formAGD .AddPlik,
.formAGD .removePlik {
  width: 48%;
  height: 150px;
}
.removePlik p {
  margin-top: 10px;
  margin-bottom: 0;
}
.formAGD .AddPlik p,
.formAGD .removePlik p {
  width: 100%;
  text-align: center;
  margin-top: 0px;
  word-break: break-all;
  margin-bottom: 0;
}
.formAGD .innerFlexFunkc {
  width: 70%;
}

.formAGD .inputTypical {
  width: 70%;
}
.inputItem {
  display: flex;
  margin: 15px 0;
  flex-direction: row;
  align-items: center;
}
.inputItem.assignee {
  position: relative;
}

.inputItem .assigneeWrapper {
  position: absolute;
  top: 30px;
  left: 30%;
  width: 70%;
  z-index: 9999;
  background: white;
  border: 1px solid #00000065;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 200px;
  overflow: auto;
  text-align: left;
}

.inputItem .assigneeWrapper .user {
  font-size: 17px;
  cursor: pointer;
}
.inputItem .assigneeWrapper .user:hover {
  background: #00000065;
  color: white;
}
.inputItem .selectedUser {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.inputItem .selectedUser p {
  width: 80%;
  font-weight: 700;
}

.inputItem .selectedUser .closeMe {
  cursor: pointer;
  font-size: 22px;
}

.inputItem p {
  margin-bottom: 0;
  width: 30%;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 154%; /* 24.64px */
  letter-spacing: -0.64px;
}
.dropdown-menu.show {
  width: 100%;
  padding: 0;
}
.inputItem p.dropdown-item:hover,
.inputItem p.dropdown-item#active {
  background: var(--CTA-orange, #f0a139);
  color: white;
}

.innerForm .close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: -50px;
}

.logo {
  border-radius: 4px;
  border: 2px solid var(--gray-02, #d8d8d8);
  background: #fff;
}
.logo:hover {
  box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 30%);
}
.logo#active {
  border-color: #abdb23;
}

.logo.addLogo input {
  width: 140px;
}

.logo.addLogo {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  height: 60px;
}

.addLogo a {
  text-decoration: none;
  color: black;
}
.logo img {
  max-height: 50px;
  width: auto;
  object-fit: cover;
}

.addNewPerson {
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #f5f5f5;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.user {
  cursor: pointer;
  position: relative;
}
.user .updateEdit {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff4f;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  height: 30px;
  width: 30px;
}

.user .removeEdit {
  position: absolute;
  top: 10px;
  right: 50px;
  height: 30px;
  width: 30px;
  background: #ffffff4f;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.user:hover img.imgprofile,
.user:hover div.default {
  box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 30%);
}
.user h4 {
  margin-top: 10px;
  color: var(--black-01, #262020);
  text-align: left;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 86.364% */
  letter-spacing: -0.88px;
  text-transform: uppercase;
}

.user p {
  color: var(--gray-03, #c0c0c0);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.56px;
}

.imgprofile {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.user .default {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}

a {
  text-decoration: none !important;
}

.logo a img {
  height: 100%;
}
.employees {
  position: relative;
}

.employees .swiper-button-prev,
.employees .swiper-button-next {
  display: none;
}

.buttonsEmployees {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.buttonsEmployees svg {
  cursor: pointer;
}
.greenArrow {
  color: #abdb23;
}
.singleTodo .Header form button {
  border-radius: 4px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 10px;
}
